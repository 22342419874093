<template>
	<v-container fluid fill-height>
    <v-row align="center" justify="center" no-gutters>
      <v-col cols="12">
        <base-heading title="Perfíl de usuario"/>
      </v-col>
    </v-row>
    <v-row>
			<v-col cols="12" sm="12" md="4">
				<base-material-card
          class="v-card-profile"
          :avatar="userData.profilePicture"
        >
          <v-card-text class="text-center pt-0">
            <h2 class="mb-1 grey--text">
							{{editedItem.fullName}}
            </h2>
						<h4 class="mb-1 grey--text">
              {{ companyData.companyVisualName }}
            </h4>
						<v-list two-line subheader>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title class="my-2 grey--text"> <h3> <b>Usuario:</b> {{editedItem.identifier}}</h3></v-list-item-title>
									<v-list-item-title class="mb-2 grey--text"> <h3> <b>Código Global Desk:</b> {{editedItem.gdpi}} </h3></v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list>
            <p class="font-weight-light">
              La información que aparece en este apartado es importante, por favor verifique que todos sus datos sean los correctos.
            </p>
          </v-card-text>

					<template v-slot:actions>
						<v-row>
							<v-col cols="12" align="center">
								<base-btn color="secondary" @click="openProfilePictureModal()" icon="mdi-camera" btnText="Cambiar Imagen de Perfíl"></base-btn>
							</v-col>
							<v-col cols="12" align="center">
								<base-btn color="primary darken-1" @click="openPasswordModal()" icon="mdi-key-variant" btnText="Cambiar Contraseña"></base-btn>
							</v-col>
						</v-row>
					</template>

        </base-material-card>
			</v-col>

			<v-col cols="12" sm="12" md="8">
				<base-form-simple ref="UserForm"
					formTitle="Datos Personales"
					:formLoading="formLoading"
					:hideDefaultActions="disabledFields"
					@cancelButton="close"
					@saveButton="save"
				>
					<template v-slot:formContent>
						<v-container class="no-padding" fluid>
							<v-row justify="center">
								<v-col cols="12" sm="4" md="4">
									<base-text-field v-model="nationality" label="Nacionalidad" disabled></base-text-field>
								</v-col>
								<v-col cols="12" sm="4" md="4">
									<base-text-field v-model="editedItem.name" label="Nombres" counter :maxlength="person.nameMaxLength" 
									:rules="[commonValRules.required, personValidationRules.nameRules.counter]" required :disabled="disabledFields"></base-text-field>
								</v-col>
								<v-col cols="12" sm="4" md="4">
									<base-text-field v-model="editedItem.surnames" label="Apellidos" counter :maxlength="person.surnamesMaxLength" 
									:rules="[commonValRules.required, personValidationRules.surnamesRules.counter]" required :disabled="disabledFields"></base-text-field>
								</v-col>

								<v-col cols="12" sm="4" md="4">
									<base-date-picker-dialog birthdateMode v-model="editedItem.birthdate" label="Fecha de Nacimiento" counter :maxlength="person.birthdateMaxLength" 
									:rules="[commonValRules.required, personValidationRules.birthdateRules.counter]" required :disabled="disabledFields"></base-date-picker-dialog>
								</v-col>
								<v-col cols="12" sm="4" md="4">
									<base-label text="Género" disabled>
										<v-radio-group class="mt-0 pt-0"
											v-model="editedItem.gender"
											row
											:rules="[commonValRules.required]" required
											:disabled="disabledFields"
										>
											<v-radio v-for="gender in genders" :key="gender.id"
												:label="gender.visualName"
												:value="gender.id"
												color="info"
											></v-radio>
										</v-radio-group>
									</base-label>
								</v-col>
							</v-row>

							<v-row>
								<v-col cols="12" sm="4" md="4">
									<base-select v-model="editedItem.documentId" :items="documents" label="Tipo Documento"
									:rules="[commonValRules.required]" required :disabled="disabledFields"></base-select>
								</v-col>
								<v-col cols="12" sm="3" md="3">
									<base-number-field fullWidth v-model="editedItem.documentNumber" label="Número Documento" counter :maxlength="person.documentNumberMaxLength"
									:rules="[personValidationRules.documentNumberRules.counter]" required :disabled="disabledFields"></base-number-field>
								</v-col>
								<v-col cols="12" sm="2" md="2">
									<base-text-field v-model="editedItem.documentComplement" label="Complemento" counter :maxlength="person.documentComplementMaxLength"
									:rules="[personValidationRules.documentComplementRules.counter]" :disabled="disabledFields"></base-text-field>
								</v-col>
								<v-col cols="12" sm="3" md="3">
									<base-select v-model="editedItem.documentExtensionId" :items="documentExtensions" label="Extensión" 
									:rules="documentExtensionIdRules" :required="documentExtensionIsRequired" :disabled="!documentExtensionIsRequired" ></base-select>
								</v-col>

								
								<v-col cols="12" sm="7" md="7">
									<base-number-field fullWidth v-model="editedItem.cellPhoneNumber" label="Número Celular" counter :maxlength="person.cellPhoneNumberMaxLength"
									:rules="[personValidationRules.cellPhoneNumberRules.counter]" required :disabled="disabledFields"></base-number-field>
								</v-col>
								<v-col cols="12" sm="5" md="5" >
									<!-- <v-alert v-if="editedItem.cellPhoneNumberVerified"
										text
										dense
										elevation="2"
										type="success"
										border="left"
										justify="center"
										align="center"
									>
										Celular Verificado
									</v-alert>
									<v-alert v-else
										text
										dense
										elevation="2"
										type="error"
										border="left"
										justify="center"
										align="center"
									>
										Celular No Verificado
									</v-alert> -->
								</v-col>
								<v-col cols="12" sm="8" md="8">
									<base-text-field v-model="editedItem.email" label="Correo Electrónico" counter :maxlength="person.emailMaxLength" 
									:rules="[commonValRules.required, commonValRules.email, personValidationRules.emailRules.counter]" required :disabled="disabledFields"></base-text-field>
								</v-col>
								<v-col cols="12" sm="4" md="4">
									<v-alert v-if="editedItem.emailVerified"
										text
										dense
										elevation="2"
										type="success"
										border="left"
										justify="center"
										align="center"
									>
										E-mail Verificado
									</v-alert>
									<v-alert v-else
										text
										dense
										elevation="2"
										type="error"
										border="left"
										justify="center"
										align="center"
									>
										E-mail No Verificado
									</v-alert>
								</v-col>


								<v-col cols="12" sm="6" md="6">
									<base-select v-model="editedItem.countryId" :items="countries" label="País" @change="loadCities"
									:rules="[commonValRules.required]" required :disabled="disabledFields"></base-select>
								</v-col>
								<v-col cols="12" sm="6" md="6">
									<base-select v-model="editedItem.cityId" :items="availableCities" label="Ciudad" 
									:rules="[commonValRules.required]" required :disabled="disabledFields"></base-select>
								</v-col>
								<v-col cols="12" sm="6" md="6">
									<base-text-field v-model="editedItem.address" label="Dirección" counter :maxlength="person.addressMaxLength"  
									:rules="[commonValRules.required, personValidationRules.addressRules.counter]" required :disabled="disabledFields"></base-text-field>
								</v-col>
								<v-col cols="12" sm="6" md="6">
									<base-number-field fullWidth v-model="editedItem.phoneNumber" label="Número Télefono" counter :maxlength="person.phoneNumberMaxLength"
									:rules="[personValidationRules.phoneNumberRules.counter]" :disabled="disabledFields"></base-number-field>
								</v-col>
							</v-row>
						</v-container>
					</template>

					<template v-slot:customFormActions>
						<base-btn color="secondary" @click="getData()" icon="mdi-reload" btnText="Actualizar"></base-btn>
						<v-spacer></v-spacer>
						<!-- <base-btn v-if="userFound" color="primary darken-1" @click="editItem()" icon="mdi-pencil" btnText="Editar"></base-btn> -->
					</template>
				</base-form-simple>
			</v-col>
		</v-row>

		<v-dialog v-model="showPasswordModal" persistent scrollable max-width="500px">
			<base-form-simple ref="PasswordForm"
				formTitle="Cambiar Contraseña"
				:formLoading="loadingPasswordForm"
				@cancelButton="closePasswordModal"
				@saveButton="saveNewPassword"
			>
				<template v-slot:formContent>
					<v-container class="no-padding" fluid>
						<v-row>
							<v-col cols="12" sm="12" md="12">
								<base-text-field
									v-model="editedPassword.lastPassword"
									label="Contraseña Actual"
									hint="Al menos 8 caracteres"
									:append-icon="showPwd1 ? 'mdi-eye' : 'mdi-eye-off'"
									:type="showPwd1 ? 'text' : 'password'"
									maxlength="25"
									@click:append="showPwd1 = !showPwd1"
									:rules="[commonValRules.required, validationRules.passwordRules.counter]"
									required
								></base-text-field>
							</v-col>
							<v-col cols="12" sm="12" md="12">
								<base-text-field 
									v-model="editedPassword.newPassword"
									label="Nueva Contraseña"
									hint="Al menos 8 caracteres"
									:append-icon="showPwd2 ? 'mdi-eye' : 'mdi-eye-off'"
									:type="showPwd2 ? 'text' : 'password'"
									maxlength="25"
									@click:append="showPwd2 = !showPwd2"
									:rules="[commonValRules.required, validationRules.passwordRules.counter, validationRules.passwordRules.valid]"
									required
								></base-text-field>
							</v-col>
							<v-col cols="12" sm="12" md="12">
								<base-text-field
									v-model="editedPassword.confirmNewPassword"
									label="Confirmar Nueva Contraseña"
									hint="Al menos 8 caracteres"
									:append-icon="showPwd3 ? 'mdi-eye' : 'mdi-eye-off'"
									:type="showPwd3 ? 'text' : 'password'"
									maxlength="25"
									@click:append="showPwd3 = !showPwd3"
									:rules="[commonValRules.required, validationRules.passwordRules.counter, validationRules.passwordRules.match]"
									required
								></base-text-field>
							</v-col>
						</v-row>
					</v-container>
				</template>
			</base-form-simple>
		</v-dialog>

		<v-dialog v-model="showProfilePictureModal" persistent scrollable max-width="400px">
			<base-form-simple
				formTitle="Cambiar Imagen de Perfíl"
				:formLoading="loadingProfilePictureForm"
				hideLabelRequiredFields
				:hideSaveButton="!hasNewProfilePicture"
				@cancelButton="closeProfilePictureModal"
				@saveButton="saveProfilePicture"
			>
				<template v-slot:formContent>
					<v-container class="no-padding" fluid>
						<v-row>
							<v-col cols="12" sm="12" md="12">
								<base-upload-file :title="'Imagen de Perfíl'" name="profilePicture" v-bind:image="newProfilePicture" v-on:onChange="onFileChange" :accept="allowedFiles">
								</base-upload-file>
							</v-col>
						</v-row>
					</v-container>
				</template>
			</base-form-simple>
		</v-dialog>
	</v-container>
</template>

<script>
import { mapMutations } from 'vuex';
// Mixins
import MainExternalData from '@/mixins/main-external-data'
import PersonData from '@/mixins/person-data'

export default {
	mixins: [MainExternalData, PersonData],
	
	data() {
		return {
			userFound: false,
			disabledFields: true,
			documents: [
        {text: "Carnet Identidad", value: 1},
        {text: "DNI", value: 2},
        {text: "Pasaporte", value: 3},
        {text: "NIT", value: 4}
      ],
      countries:[],
      nationalities:[],
			cities:[],
			availableCities:[],
			documentExtensionIsRequired: false,
			editedItem: {
        id: 0,
				identifier: '',
				gdpi: '',
        nationalityId: 0,
        fullName:'',
        name: '',
				surnames: '',
				gender:'',
				birthdate: '',
        documentId: 0,
        documentNumber: '',
				documentComplement: '',
        documentExtensionId: '',
        countryId: 0,
				cityId: '',
        address: '',
        phoneNumber: '',
        email: '',
        cellPhoneNumber: '',
        emailVerified: false,
        cellPhoneNumberVerified: false,
        enabled: false,
      },
      defaultItem: {
        id: 0,
				identifier: '',
				gdpi: '',
        nationalityId: 0,
        fullName:'',
        name: '',
				surnames: '',
				gender:'',
				birthdate: '',
        documentId: 0,
        documentNumber: '',
				documentComplement: '',
        documentExtensionId: '',
        countryId: 0,
				cityId: '',
        address: '',
        phoneNumber: '',
        email: '',
        cellPhoneNumber: '',
        emailVerified: false,
        cellPhoneNumberVerified: false,
        enabled: false,
			},
			tempItem:{},
			formLoading: false,

			showPwd1: false,
			showPwd2: false,
			showPwd3: false,
			editedPassword: {
        lastPassword: '',
				newPassword: '',
				confirmNewPassword: '',
      },
      defaultPassword: {
        lastPassword: '',
				newPassword: '',
				confirmNewPassword: '',
      },
			showPasswordModal: false,
			loadingPasswordForm: false,

			allowedFiles:['.jpeg', '.jpg', '.png'],
			newProfilePicture: null,
			showProfilePictureModal: false,
			loadingProfilePictureForm: false,

			validationRules: {
				passwordRules: {
					counter: value => (value.length >= 8 && value.length <= 25) || 'No debe tener más de 25 caracteres, ni menos de 8 caracteres.',
					valid: value => (value !== this.editedPassword.lastPassword) || 'No debe ser igual a la contraseña actual.',
					match: value => (value === this.editedPassword.newPassword) || 'No coincide con la nueva contraseña.',
        },
			}
		}
	},

	computed: {
		nationality(){
			var nationalityFound = this.nationalities.find(x => x.value == this.editedItem.nationalityId)
			if (nationalityFound === null || nationalityFound === undefined) {
				return 'No Disponible.';
			}
			return nationalityFound.text;
		},

		documentExtensions() {
			return this.editedItem.documentId == this.$Const.DOCUMENT_TYPE.CI ? this.extensions : [];
    },

		// documentExtensions(){
		// 	var extensions = [];
		// 	const available = this.cities.filter(x => x.countryId === 28);//Bolivia
    //   available.map(function(x){
    //     extensions.push({text:x.visualName, value:x.id});
    //   });
    //   return extensions;
		// },

		documentExtensionIdRules(){
			var rules=[];
			if (this.disabledFields) {
				this.documentExtensionIsRequired = false;
				return rules;
			}

			if (this.editedItem.documentId === this.$Const.DOCUMENT_TYPE.CI) {
        rules.push(this.commonValRules.required);
        this.documentExtensionIsRequired = true;
      }
      else {
        this.editedItem.documentExtensionId = '';
        this.documentExtensionIsRequired = false;
      }
			return rules;
		},

		hasNewProfilePicture() {
			if (this.newProfilePicture) {
				return true
			}
			return false
		}
	},

	created() {
		this.listOfParameters();
		this.listOfCountries();
    this.listOfNationalities();
		this.listOfCities();
		this.getData();
	},

	methods: {
		...mapMutations(['SET_PROFILE_PICTURE', 'SET_USER_FULLNAME']),

		async listOfParameters(){
      let me=this;
			await me.getListOfParameterValuesResponse([me.$Const.PARAMETERS.DOCUMENTS, me.$Const.PARAMETERS.EXTENSIONS]).then(data => {
        if (data !== undefined) {
          let documentArray = data.find(x => x.id == me.$Const.PARAMETERS.DOCUMENTS).values;
          documentArray.map(function(x){
            me.documents.push({text:x.visualName, value:x.id});
          });

          let extensionsArray = data.find(x => x.id == me.$Const.PARAMETERS.EXTENSIONS).values;
          extensionsArray.map(function(x){
            me.extensions.push({text:x.visualName, value:x.id});
          });
        }
      }).catch(function(error){
        me.$swal(me.swalConfig.errorTitle, error.message, 'error');
      });
    },

		async listOfCountries(){
      let me=this;
			var countriesArray=[];
			await me.getObjectResponse('api/Parameter/Countries', me.baseRequest).then(data => {
				if (data !== undefined) {
					countriesArray = data.countries; 
					countriesArray.map(function(x){
            me.countries.push({text:x.visualName, value:x.id});
          });
				}
			}).catch(function(error){
        me.$swal(me.swalConfig.errorTitle, error.message, 'error');
      });
    },

    async listOfNationalities(){
      let me=this;
			var nationalitiesArray=[];
			await me.getObjectResponse('api/Parameter/Nationalities', me.baseRequest).then(data => {
				if (data !== undefined) {
					nationalitiesArray = data.nationalities; 
					nationalitiesArray.map(function(x){
            me.nationalities.push({text:x.visualName, value:x.id});
          });
				}
			}).catch(function(error){
        me.$swal(me.swalConfig.errorTitle, error.message, 'error');
      });
    },
    
    async listOfCities(){
      let me=this;
			await me.getObjectResponse('api/Parameter/Cities', me.baseRequest).then(data => {
				if (data !== undefined) {
					me.cities = data.cities; 
				}
			}).catch(function(error){
        me.$swal(me.swalConfig.errorTitle, error.message, 'error');
      });
		},
		
		loadCities(){
      this.availableCities=[];
      this.editedItem.cityId='';
      if (!this.editedItem.countryId || this.editedItem.countryId === 0) {
        return;
      }

      const available = this.cities.filter(x => x.countryId === this.editedItem.countryId);
      var result =[];
      available.map(function(x){
        result.push({text:x.visualName, value:x.id});
      });
      this.availableCities=result
		},
		
		async getData() {
			let me=this;
			try {
				me.SHOW_LOADING()
				me.userFound = false
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'id': me.baseRequest.userId,
				};
				await me.getObjectResponse('api/User/GetById', request).then(data => {
					if (data === undefined) {
						return;
					}
					me.editedItem = Object.assign({}, data.user);
					me.loadCities();
					me.editedItem.cityId = data.user.cityId
					me.userFound = true;
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
		},

		editItem(){
			this.tempItem = Object.assign({}, this.editedItem);
			this.disabledFields = false;
		},

		async save() {
			let me=this;
			try {
        me.SHOW_LOADING()
        me.formLoading=true;
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'id': me.editedItem.id,
					'identifier': me.editedItem.identifier,
					
					'nationalityId': me.editedItem.nationalityId,
					'name': me.editedItem.name,
					'surnames': me.editedItem.surnames,
					'gender': me.editedItem.gender,
					'birthdate': me.editedItem.birthdate,
					'documentId': me.editedItem.documentId,
					'documentNumber': me.editedItem.documentNumber,
					'documentComplement': me.editedItem.documentComplement,
					'documentExtensionId': me.editedItem.documentExtensionId,
					'countryId': me.editedItem.countryId,
					'cityId': me.editedItem.cityId,
					'address': me.editedItem.address,
					'phoneNumber': me.editedItem.phoneNumber,
					'cellPhoneNumber': me.editedItem.cellPhoneNumber,
					'email': me.editedItem.email,
				};
				await me.getBaseResponse('api/User/UpdateData', request).then(data => {
					if (data) {
						me.clean();
						me.getData();
						me.SET_USER_FULLNAME(me.editedItem.fullName);
					}
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
        me.formLoading=false;
      }
		},
		
		close() {
      this.editedItem = Object.assign({}, this.tempItem);
			this.clean();
		},
		
		clean() {
			this.$refs.UserForm.reset();
			this.tempItem = Object.assign({}, this.defaultItem);
			this.disabledFields=true;
		},

		openPasswordModal() {
      this.showPasswordModal = true;
    },
		
		async saveNewPassword(){
			let me=this;
			try {
				me.SHOW_LOADING()
				me.loadingPasswordForm = true
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'identifier': me.editedItem.identifier,
					'lastPassword': me.editedPassword.lastPassword,
					'newPassword': me.editedPassword.newPassword,
				};
				await me.getBaseResponse('api/User/ChangePassword', request).then(data => {
					if (data) {
						me.closePasswordModal();
					}
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
        me.loadingPasswordForm = false
      }
		},

    closePasswordModal() {
			this.$refs.PasswordForm.reset();
      this.editedPassword = Object.assign({}, this.defaultPassword);
      this.showPasswordModal = false;
		},
		

		openProfilePictureModal() {
      this.showProfilePictureModal = true;
		},
		
		onFileChange(name, file){
			this.newProfilePicture = null;
			if (file){
				this.newProfilePicture = file.base64;
			}
		},
		
		async saveProfilePicture(){
			let me=this;
			try {
				me.SHOW_LOADING()
				me.loadingProfilePictureForm = true;
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'profilePicture': me.newProfilePicture,
				};
				await me.getBaseResponse('api/Account/ChangeProfilePicture', request).then(data => {
					if (data) {
						me.SET_PROFILE_PICTURE(me.newProfilePicture);
						me.closeProfilePictureModal();
					}
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
        me.loadingProfilePictureForm = false;
      }
		},

    closeProfilePictureModal() {
      this.newProfilePicture = null;
      this.showProfilePictureModal = false;
    },
	},
}
</script>